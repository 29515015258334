import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import axios from "axios";
import { useTranslation } from "react-i18next";
import "../../i18n.js";

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogBanner from "../components/blog-banner/blogBanner";

const ContactPage = () => {
  const { t } = useTranslation();

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    text: t("contactDescription")
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      text: `← ${t("goBackHome")}`
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true, text: t("loading") });
    axios({
      method: "post",
      url: "https://getform.io/f/d9a723b6-b4b7-4380-9cef-957ece024353",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, t("thanks"), form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Layout navbarTheme="light" className="bg-blue">
      <Seo title={t("metaContactTitle")} description={t("metaContactDescription")} canonical="https://tio.ist/contact/" />

      <div className="mt-28 lg:mt-32">
        <div className="mb-16 lg:mb-16">
          <h1 className="mx-5 md:mx-16 text-6xl md:text-8xl lg:text-9xl font-extrabold text-white">
            {t("thinkWeFit")}
          </h1>
          <div className="ticker-wrap-v2 bg-transparent text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="ticker z-10">
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
            </div>
            <div className="ticker z-10">
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
              <div className="ticker-item w-auto mr-5 md:mr-20">
                <p>{t("letsTalk")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-10 mx-5 md:mx-16">
          <p className="text-xl lg:text-3xl font-light text-cream">
            {t("contactDescription")}
          </p>
          {serverState.status?.ok && <Link to="/" className="inline-block text-white text-lg lg:text-xl italic font-light mt-20 border-b border-transparent hover:border-white">{serverState.text}</Link>}
          {serverState.status === null && serverState.submitting && <p className="text-white text-lg lg:text-xl italic font-light mt-20">{serverState.text}</p>}
          {!serverState.status?.ok &&
            <form id="form" onSubmit={handleOnSubmit} name="contact" method="post" className="max-w-[15rem] md:max-w-3xl mx-auto mt-32 space-y-8" >
              <input type="hidden" name="form-name" value="contact" />
              <input className="block outline-none p-3 border text-white w-full border-white bg-transparent placeholder-white/80 text-lg font-light" required type="text" name="name" placeholder={t("nameSurnamePlaceholder")} />
              <input className="block outline-none p-3 border text-white w-full border-white bg-transparent placeholder-white/80 text-lg font-light" required type="email" name="email" placeholder={t("emailPlaceholder")} />
              <select className="select-icon block outline-none rounded-none p-3 border text-white w-full border-white bg-transparent text-lg font-light appearance-none bg-no-repeat" type="text" name="budget" required>
                <option defaultValue>{t("budgetPlaceholder")}</option>
                <option value="10K-20K">{t("min10K")}</option>
                <option value="20K - 50K">{t("min20K")}</option>
                <option value="MORE THEN 50K">{t("min50K")}</option>
              </select>
              <textarea className="block outline-none p-3 border text-white w-full border-white bg-transparent placeholder-white/80 text-lg font-light resize-marker" required rows="5" name="message" placeholder={t("yourMessagePlaceholder")} />
              <button className="bg-white px-5 py-2 text-blue flex justify-start text-lg font-light" type="submit">{t("sendCTA")}</button>
            </form>
          }
        </div>

        <div className="mx-5 md:mx-16 py-10 mt-52">
          <h2 className="text-white text-6xl md:text-8xl lg:text-9xl mb-6"><span className="font-bold">{t("get")}</span>{" "}<span className="italic">{t("inTouch")}</span></h2>
          <p className="text-cream text-xl lg:text-3xl max-w-[15rem] md:max-w-3xl font-light">{t("getInTouchDescription")}</p>
          <div className="mt-32 md:flex flex-wrap md:justify-between max-w-[15rem] md:max-w-xl lg:max-w-6xl space-y-16 md:space-y-0">
            <div className="md:w-1/2 lg:w-1/4 space-y-6 md:mb-16 lg:mb-0">
              <h3 className="text-white text-xl font-light">{t("emailText")}</h3>
              <a href="mailto:merhaba@tio.ist" target="_blank" rel="noreferrer" className="block text-white text-2xl font-bold">{t("contactEmailInfo")}</a>
            </div>
            <div className="md:w-1/2 lg:w-1/4 space-y-6 md:mb-16 lg:mb-0">
              <h3 className="text-white text-xl font-light">{t("phoneText")}</h3>
              <a href="tel:+905465594746" target="_blank" rel="noreferrer" className="block text-white text-2xl font-bold">+90 546 559 47 46</a>
            </div>
            <div className="md:w-1/2 lg:w-1/4 space-y-6">
              <h3 className="text-white text-xl font-light">{t("locationText")}</h3>
              <p className="text-white text-2xl font-bold">{t("locationInfo")}</p>
            </div>
            <div className="md:w-1/2 lg:w-1/4 space-y-6">
              <h3 className="text-white text-xl font-light">{t("socialsText")}</h3>
              <div className="space-x-6">
                <a href="https://instagram.com/tio.ist" target="_blank" rel="noreferrer" className="font-bold text-white text-2xl">{t("ig")}</a>
                <a href="https://facebook.com/tio.ist" target="_blank" rel="noreferrer" className="font-bold text-white text-2xl">{t("fb")}</a>
                <a href="https://behance.net/tioist" target="_blank" rel="noreferrer" className="font-bold text-white text-2xl">{t("be")}</a>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-5 md:mx-16 py-10 my-20">
          <div>
            <StaticImage src="../images/contact-tio.jpg" alt="workspace" formats={["auto", "webp", "avif"]} />
          </div>

          <div className="flex justify-between mt-6">
            <p className="text-white italic text-sm lg:text-xl">{t("ourOffice")} →</p>
            <p className="text-white italic text-sm lg:text-xl max-w-[10rem] md:max-w-none text-right">{t("locality")}</p>
          </div>
        </div>
      </div>

      <BlogBanner />
    </Layout>
  )
}

export default ContactPage